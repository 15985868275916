import { observable, action, computed, makeObservable } from 'mobx';

class StaticStore {
  constructor() {
    makeObservable(this, {
      width: observable,
      height: observable,
      isMobile: observable,
      setWidth: action,
      setHeight: action
    });
  }

  width = null;
  height = null;
  isMobile = false;
  

  setWidth(width){
    this.width = width;
    if(width < 1000){
      this.isMobile = true;
    }
  }

  setHeight(height){
    this.height = height;
  }

}

const staticStore = new StaticStore();
export default staticStore;