import axios from "axios";

//baseURL:"http://172.30.60.46:5001/",

export const customAxios = axios.create({
  baseURL:"https://api.selffix.co/",
  headers:{
    "Access-Control-Allow-Origin":"*"
  }
})
