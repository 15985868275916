import { observable, action, computed, makeObservable } from 'mobx';
import AgoraRTC from "agora-rtc-sdk-ng"
import socketStore from './socketStore';


class AgoraStore {
  constructor() {
    makeObservable(this, {
      appId: observable,
      channel: observable,
      channel: observable,
      uid: observable,
      isMute:observable,
      localAudioTrack:observable,
      localVideoTrack:observable,
      localShareScreenTrack:observable,
      remoteAudioTrack:observable,
      screenShare:observable,
      remoteVideoTrack:observable,
      isCamera:observable,
      remoteId:observable,
      setAppId: action,
      setChannel: action,
      setToken: action,
      setUId: action,
      setLocalAudioTrack: action,
      setLocalShareScreenTrack: action,
      setLocalVideoTrack: action,
      setIsMute:action,
      setIsCamera:action,
      shareScreen: action,
      setRemoteAudioTrack: action,
      stopScreenShare: action,
      setRemoteVideoTrack: action,
      setRemoteId: action,
    });

    this.setAppId(process.env.REACT_APP_AGORA_APP_ID);
    this.setUId(Math.round(Math.random()*10000));
    
  }

  appId = "";
  channel = "";
  token = "";
  uid = "";
  host=false;

  localAudioTrack = null;
  localVideoTrack = null;
  localShareScreenTrack = null;

  remoteAudioTrack = null;
  remoteVideoTrack = null;

  screenShare = false;
  isMute = false;
  isCamera = false;

  remoteId= null;
  agoraShareScreenEngine = null;
  agoraEngine = null;

  setHost(host){
    this.host = host;
  }

  setIsCamera = async () => {
    this.isCamera = !this.isCamera;
    if(this.isCamera){
      if(!this.localVideoTrack){
        const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        this.setLocalVideoTrack(localVideoTrack);
        await this.startCamera()
      }else{
        this.localVideoTrack.setMuted(!this.isCamera);
      }
    }else{
      this.localVideoTrack.setMuted(!this.isCamera);
    }
  }

  setIsMute(isMute){
    this.isMute = isMute;
  }

  setLocalAudioTrack(localAudioTrack){
    this.localAudioTrack = localAudioTrack
  }
  setLocalVideoTrack(localVideoTrack){
    this.localVideoTrack = localVideoTrack
  }

  setRemoteAudioTrack(remoteAudioTrack) {
    this.remoteAudioTrack = remoteAudioTrack
  }

  setRemoteVideoTrack(remoteVideoTrack) {
    this.remoteVideoTrack = remoteVideoTrack
  }

  setRemoteId(remoteId){
    this.remoteId = remoteId
  }

  setAppId(appId){
    this.appId = appId
  }

  setLocalShareScreenTrack(localShareScreenTrack){
    this.localShareScreenTrack = localShareScreenTrack;
  }

  setChannel(channel){
    this.channel = channel
  }

  setToken(token){
    this.token = token
  }

  setUId(uid){
    this.uid = uid
  }

  leaveCall = async () => {
    await this.agoraEngine.leave();
    await this.localAudioTrack.stop();
    await this.localVideoTrack.stop();
    await this.localAudioTrack.close();
    await this.localVideoTrack.close();
  }

  userPublished = async (user, mediaType,document) => {
    const remotePlayerContainer = document.getElementById("remote");

    const _remotePlayerContainer = document.createElement("div");
    
    _remotePlayerContainer.style.width = "100%";
    _remotePlayerContainer.style.height = "100%";
    _remotePlayerContainer.style.objectFit = "fill"

    

    await this.agoraEngine.subscribe(user, mediaType);
    
    if(mediaType === "video"){

      if(remotePlayerContainer.children.length > 0){
        for(let i = 0; i < remotePlayerContainer.children.length; i++){
          const c = remotePlayerContainer.children[i];
          c.remove();
        }
      }

      this.setRemoteVideoTrack(user.videoTrack);
      
      this.setRemoteId(user.uid.toString());
      _remotePlayerContainer.id = user.uid.toString();
      remotePlayerContainer.append(_remotePlayerContainer);

      this.remoteVideoTrack.play(_remotePlayerContainer)
      socketStore.setNoResponse(false);
      socketStore.setToPrompter(false);
      setTimeout(() => {
        const elems = document.getElementsByClassName("agora_video_player");
        elems[0].style.objectFit = "contain"
      },1000)
      // this.remoteAudioTrack.play();
    }else{
      this.setRemoteAudioTrack(user.audioTrack);
      this.remoteAudioTrack.play();
    }
  }

  userUnPublished = async (user) => {
    // const _d = document.getElementById(user.uid);
    // _d.remove();
  }

  userPublishedScreenShare = async (user, mediaType,document) => {
    // console.log("🚀 ~ file: agoraStore.js:96 ~ AgoraStore ~ userPublished= ~ userPublished")
    
    // const remotePlayerContainer = document.getElementById("remote");

    // const _remotePlayerContainer = document.createElement("div");
    
    // _remotePlayerContainer.style.width = "100%";
    // _remotePlayerContainer.style.height = "100%";

    // await this.agoraEngine.subscribe(user, mediaType);
    
    // if(mediaType === "video"){
    //   this.setRemoteVideoTrack(user.videoTrack);
    //   this.setRemoteAudioTrack(user.audioTrack);
    //   this.setRemoteId(user.uid.toString());
    //   _remotePlayerContainer.id = user.uid.toString();
    //   remotePlayerContainer.append(_remotePlayerContainer);

    //   this.remoteVideoTrack.play(_remotePlayerContainer)
    // }
  }

  userUnPublishedScreenShare = async (user) => {
    // console.log("🚀 ~ file: agoraStore.js:82 ~ AgoraStore ~ userUnPublished= ~ user", user)
    // const _d = document.getElementById(user.uid);
    // _d.remove();
  }

  stopScreenShare = async () => {
    this.localShareScreenTrack.stop();
    this.localShareScreenTrack.close();
    await this.agoraEngine.unpublish(this.localShareScreenTrack);
    await this.agoraEngine.publish([this.localAudioTrack,this.localVideoTrack]);
      
    this.localShareScreenTrack = null;
    this.screenShare = false;
  }

  startCamera = async () => {
    const localPlayerContainer = document.getElementById("local");

    const _localPlayerContainer = document.createElement('div');

    _localPlayerContainer.style.width = "100%";
    _localPlayerContainer.style.height = "100%";

    _localPlayerContainer.id = this.uid;

    localPlayerContainer.append(_localPlayerContainer);

    this.localVideoTrack.play(_localPlayerContainer);

    await this.agoraEngine.publish([this.localAudioTrack,this.localVideoTrack]);
  }


  join = async (channel) => {
    await this.agoraEngine.join("ed4c0ffd56054721a1b4cd90e0cf0dfd", channel, null ,this.uid);
    await this.agoraEngine.publish([this.localAudioTrack]);
  }

  setMute = () => {
    this.isMute = !this.isMute;
    this.localAudioTrack.setMuted(this.isMute);
  }

  shareScreen = async (channel) => {

    const shareScreenTrack = await AgoraRTC.createScreenVideoTrack({});
    this.screenShare = true;
    setTimeout( async () => {
      this.setLocalShareScreenTrack(shareScreenTrack);

      const sharePlayerContainer = document.getElementById("screenShare");

      const _sharePlayerContainer = document.createElement('div');

      _sharePlayerContainer.style.width = "100%";
      _sharePlayerContainer.style.height = "100%";

      _sharePlayerContainer.id = this.uid;

      sharePlayerContainer.append(_sharePlayerContainer);
        
      this.localShareScreenTrack.play(_sharePlayerContainer);

      await this.agoraEngine.unpublish(this.localVideoTrack);
      await this.agoraEngine.publish([this.localAudioTrack,this.localShareScreenTrack]);
      
    },500)
  }

  init = async (document) => {
    return new Promise( async (resolve) => {
      
      this.agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

      const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Create a local video track from the video captured by a camera.
      // const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      

      this.setLocalAudioTrack(localAudioTrack);
      // this.setLocalVideoTrack(localVideoTrack);

      this.agoraEngine.on("user-published", async (user,mediaType) => this.userPublished(user,mediaType,document));
      this.agoraEngine.on("user-unpublished", async (user,mediaType) => this.userUnPublished(user,mediaType,document));    
      resolve(true);
    })
  }

}

const agoraStore = new AgoraStore();
export default agoraStore;