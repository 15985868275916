import React, { useEffect, useState } from "react";
import styles from "./callCenter.module.css";
import { observer } from "mobx-react";
import Div100vh from 'react-div-100vh'
import axios from "axios";
import { Redirect } from "react-router-dom";
import { customAxios } from "../helpers/axios";

class CreateCall extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      redirect: false,
      name: "Test User",
      surname: "Test User Surname",
      email: "a@a.com",
      phone: "+90 555 555 5555",
      iframeUrl: null,
    }
  }

  render() {
    const {
      redirect,
      name,
      surname,
      email,
      phone,
      iframeUrl
    } = this.state;

    //  if(redirect){
    //   return <Redirect  to={redirect}/>
    // }

    const TEMP_WIDTH = 750;
    const TEMP_HEIGHT = 1200

    return (
      <Div100vh className={styles.container} style={{ justifyContent: "center", alignItems: "center" }}>
        <span>Create Call</span>
        <input placeholder="Name" value={name} onChange={(e) => {
          this.setState({ name: e.target.value })
        }} className={styles.messageInput} style={{ padding: 5, width: "50vh", marginTop: 20, height: "100px", flex: 0 }} type="text" />
        <input placeholder="Surname" value={surname} onChange={(e) => {
          this.setState({ surname: e.target.value })
        }} className={styles.messageInput} style={{ padding: 5, width: "50vh", marginTop: 20, height: "100px", flex: 0 }} type="text" />
        <input placeholder="E-Mail" value={email} onChange={(e) => {
          this.setState({ email: e.target.value })
        }} className={styles.messageInput} style={{ padding: 5, width: "50vh", marginTop: 20, height: "100px", flex: 0 }} type="email" />
        <input placeholder="Phone" value={phone} onChange={(e) => {
          this.setState({ phone: e.target.value })
        }} className={styles.messageInput} style={{ padding: 5, width: "50vh", marginTop: 20, height: "100px", flex: 0 }} type="phone" />

        <div onClick={async () => {
          const customer = {
            email,
            phone,
            surname,
            name
          };
          customAxios.post("/live-store/encrypt", { customer, companyId: "606338fbddcb9b5d42f961ce" }).then((r) => {

            const { message } = r.data;
            customAxios.post("/live-store/create", { message, companyId: "606338fbddcb9b5d42f961ce" }).then((_r) => {
              this.setState({ iframeUrl: _r.data.url.replace("https://live-store.selffix.co","http://localhost:3000") });
            });
          });

        }} className={styles.button} style={{ width: "50vh", borderRadius: 8, color: "white", marginTop: 8 }}>
          Create Call
        </div>

        {iframeUrl ?
        
        <div
  id="colorbox"
  className=""
  role="dialog"
  tabIndex={-1}
  style={{
    display: "block",
    visibility: "visible",
    top: 75,
    left: 50,
    position: "fixed",
    width: TEMP_WIDTH,
    height: TEMP_HEIGHT,
    overflow: "hidden"
  }}
>
  <div id="cboxWrapper" style={{ height: TEMP_HEIGHT, width: TEMP_WIDTH }}>
    <div>
      <div id="cboxTopLeft" style={{ float: "left" }} />
      <div id="cboxTopCenter" style={{ float: "left", width: TEMP_WIDTH }} />
      <div id="cboxTopRight" style={{ float: "left" }} />
    </div>
    <div style={{ clear: "left" }}>
      <div id="cboxMiddleLeft" style={{ float: "left", height: TEMP_HEIGHT }} />
      <div
        id="cboxContent"
        style={{ float: "left", width: TEMP_WIDTH, height: TEMP_HEIGHT }}
      >
        <div
          id="cboxLoadedContent"
          style={{ width: TEMP_WIDTH, overflow: "auto", height: TEMP_HEIGHT }}
        >
          <iframe
            frameBorder={0}
            width={TEMP_WIDTH}
            height={TEMP_HEIGHT}
            name="selfixframe"
            id="selfixFrame"
            allowFullScreen=""
            src={iframeUrl}
            allow="camera; microphone"
          />
        </div>
        <div id="cboxTitle" style={{ float: "left" }} />
        <div id="cboxCurrent" style={{ float: "left", display: "none" }} />
        <button type="button" id="cboxPrevious" style={{ display: "none" }} />
        <button type="button" id="cboxNext" style={{ display: "none" }} />
        <button type="button" id="cboxSlideshow" style={{ display: "none" }} />
        <div
          id="cboxLoadingOverlay"
          style={{ float: "left", display: "none" }}
        />
        <div
          id="cboxLoadingGraphic"
          style={{ float: "left", display: "none" }}
        />
        <button type="button" id="cboxClose">
          close
        </button>
      </div>
      <div id="cboxMiddleRight" style={{ float: "left", height: 1134 }} />
    </div>
    <div style={{ clear: "left" }}>
      <div id="cboxBottomLeft" style={{ float: "left" }} />
      <div id="cboxBottomCenter" style={{ float: "left", width: 1725 }} />
      <div id="cboxBottomRight" style={{ float: "left" }} />
    </div>
  </div>
  <div
    style={{
      position: "absolute",
      width: 9999,
      visibility: "hidden",
      maxWidth: "none",
      display: "none"
    }}
  />
</div>



          : null}
      </Div100vh>
    )
  }
}

export default CreateCall