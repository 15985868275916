import { action, makeAutoObservable, observable } from 'mobx';
import {io} from 'socket.io-client';
import agoraStore from './agoraStore';

class SocketStore {

  slug = null;
  token = null;
  messages = [];
  products = [];
  hasNewMessage = false;
  end_call = false;
  ratingGiven = false;
  to_prompter = false;
  no_response = false;
  
  constructor() {
    makeAutoObservable(this,{
      messages:observable,
      token:observable,
      products:observable,
      ratingGiven:observable,
      end_call:observable,
      hasNewMessage:observable,
      sendMessage:action,
      setHasNewMessage:action,
      setMessagesInit:action,
      insertNewMessageFromRemote:action,
      setEndCall:action,
      setRatingGiven:action,
    })
  }

  setEndCall(end_call){
    this.end_call = end_call;
  }
  
  setRatingGiven(ratingGiven){
    this.ratingGiven = ratingGiven;
  }

  setToken(token){
    this.token = token;
  }

  setMessagesInit(messages){
    const temp = messages.map((m) => {
      return {
        _id:m._id,
        user:m.from === "agent" ? "remote" : "local",
        text:m.message.content
      }
    });
    this.messages = temp.reverse()
  }

  

  sendMessage(args, isAgent = false) {
    const data = {
      payload: args,
      isAgent,
      slug: this.slug
    }

    if(args.type === "message"){
      this.messages.unshift({
        _id:"local#"+new Date().getTime(),
        text:args.message.content,
        user:{
          _id: "local"
        }
      })
    }

    this.socket.emit("live-store", data);
  }

  insertNewMessageFromRemote(text){
    
    this.messages.unshift({
      _id:"remote#"+new Date().getTime(),
      text,
      user:"remote"
    })
    this.hasNewMessage = true;
  }

  setHasNewMessage(hasNewMessage){
    this.hasNewMessage = hasNewMessage;
  }

  setNoResponse(no_response){
    this.no_response = no_response;
  }
  
  setToPrompter(to_prompter){
    this.to_prompter = to_prompter;
  }

  insertNewProduct(product){
    this.products.push(product);
  }

  setProductsInit(products){
    this.products = products;
  }

  init(token,slug){
    this.socket = io("https://api.selffix.co:5001",{
      reconnection:true,
      reconnectionAttempts:50,
      reconnectionDelay:5000,
      autoConnect:true,
      transports: ['websocket'],
      secure: true,
      auth:{
        token
      }
    })

    this.token = token;
    this.slug = slug;

    // client-side


    this.socket.on("connect", () => {
      console.log(this.socket.id); // x8WIv7-mJelg7on_ALbx
    });

    this.socket.on("connect_error", () => {
      console.log("ERROR")
    });

    this.socket.on(slug, (data) => {
      if(data.action === "end_call"){
        this.setEndCall(true);
        agoraStore.leaveCall().then(() => {
          
        })
        return;
      }else if(data.action === "to_prompter"){
        agoraStore.setRemoteId(null);
        this.setEndCall(false);
        return;
      }else if(data.action === "no_response"){
        this.setToPrompter(false);
        this.setNoResponse(true);
      }else if(data.action === "to_prompter_no_response"){
        this.setToPrompter(true);
      }

      if(data.type === "message"){
        this.insertNewMessageFromRemote(data.message.content)
      }else if(data.type === "product"){
        this.insertNewProduct(data.url);
      }
    });

    
    this.socket.on("disconnect", () => {
      console.log(this.socket.id); // undefined
    });
  }
  
  
}

const socketStore = new SocketStore();
export default socketStore;